import React, { memo, useContext } from 'react';
// Libraries
import Pagination from './style';

import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';

function AntPagination({
  onChange,
  currentPage,
  limit,
  onShowSizeChange,
  total,
  showTotal,
  showSizeChanger,
  showQuickJumper,
}) {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Pagination
      textColor={theme.color.textOnPrimaryBackground}
      primary={theme.color.primary}
      borderColor={theme.color.primary}
      defaultCurrent={1}
      pageSize={limit || 10}
      current={currentPage}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      total={total}
      showLessItems={isMobile && true}
      showSizeChanger={showSizeChanger}
      showQuickJumper={showQuickJumper}
      showTotal={showTotal}
    />
  );
}

export default memo(AntPagination);
