import React, { useContext, memo } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import Text from '~/components/typography/text';

import { CapitalizeString } from '~/utils/Transformations';

import { Div } from './style.js';

function PrimaryTextCell({ text, textAlign, strong }) {
  const { theme } = useContext(ThemeContext);
  return (
    <Div>
      <Text
        style={{ textAlign }}
        strong={strong || false}
        color={theme.color.textOnSecondaryBackground}
      >
        {CapitalizeString(text)}
      </Text>
    </Div>
  );
}

export default memo(PrimaryTextCell);
