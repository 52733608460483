import React, { useContext, memo } from 'react';

// Standalone components
import { LoadContainer, Spinner, LoadingIcon } from './style';
import { ThemeContext } from '~/contexts/ThemeContext';

function AntLoading({ height, text }) {
  const { theme } = useContext(ThemeContext);
  return (
    <LoadContainer height={height || '300px'}>
      <Spinner
        style={{ color: theme.color.primary }}
        color={theme.color.primary}
        indicator={<LoadingIcon color={theme.color.primary} spin />}
        tip={text || 'Carregando...'}
      />
    </LoadContainer>
  );
}

export default memo(AntLoading);
