import authentication from './authentication';
import basic from './basic';
import brands from './brands';
import fields from './fields';
import messages from './messages';

const Request = {
  authentication,
  basic,
  brands,
  fields,
  messages,
};

export default Request;
