import React, { memo } from 'react';
// Standalone Components
import { MainDivider } from './style';

function AntDivider({
  className,
  dashed,
  orientation,
  plain,
  type,
  children,
  style
}) {
  return (
    <MainDivider
      className={className}
      dashed={dashed}
      orientation={orientation}
      plain={plain}
      type={type}
      style={style}
    >
      {children}
    </MainDivider>
  )
}

export default memo(AntDivider);
