import React, { memo } from 'react';
// Standalone Components
import { MainAvatar } from './style';

function AntAvatar({
  alt,
  gap,
  icon,
  shape,
  size,
  src,
  srcSet,
  onError,
  children,
  style,
}) {
  return (
    <MainAvatar
      alt={alt}
      gap={gap}
      icon={icon}
      shape={shape}
      size={size}
      src={src}
      srcSet={srcSet}
      onError={onError}
      style={style}
    >
      {children}
    </MainAvatar>
  );
}

export default memo(AntAvatar);
