import React, { useState, useEffect, useContext } from 'react';
// Components
import { Card, Typography } from 'antd';

import SocialMeAPI from '~/utils/SocialMeAPI';
import Pagination from '~/components/pagination';
import Loading from '~/components/loading';
import Divider from '~/components/divider';

import MovesTable from './components/MovesTable';

import Debug from '~/environments/Debug';

import { ThemeContext } from '~/contexts/ThemeContext';

import { Container } from './style';

const { Title } = Typography;

export default function Moves() {
  const [loading, setLoading] = useState(false);
  const [moves, setMoves] = useState([]);

  const [totalItems, setTotalItems] = useState(100);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { theme } = useContext(ThemeContext);

  const isDebug = Debug.Giver.Moves;

  useEffect(() => {
    async function GetDonationsMoves() {
      setLoading(true);

      const params = {
        operation: ['Donation', 'Moves'],
        urlParams: {
          accountType: 'CC',
        },
        data: {
          pagination: {
            limit,
            page: currentPage,
            sort: {
              by: 'created_at',
              order: 'desc',
            },
          },
          filter: {
            outcome: true,
            kind: ['SME-INTERNAL-DONATION-CC-CS'],
          },
        },
      };

      if (isDebug) console.log('📦 Params: ', params);

      const response = await SocialMeAPI(params);

      if (isDebug) console.log('🚀 API Response, Donation Moves: ', response);

      if (response.success) {
        if (isDebug) console.log('Transactions maded was found');

        console.log(response.body.detailedBalance.elements);

        setMoves(response.body.detailedBalance.elements);

        setTotalItems(response.body.detailedBalance.totalElements);
      } else if (isDebug) {
        console.log('Not transaction was made');
      }

      setLoading(false);
    }
    GetDonationsMoves();
  }, [limit, currentPage, isDebug]);

  return (
    <div>
      <Card>
        <Container>
          <Title level={4}>Minhas movimentações</Title>
        </Container>
        <Divider style={{ borderColor: theme.color.white }} />
        {loading ? (
          <Loading />
        ) : moves.length > 0 ? (
          <>
            <MovesTable moves={moves} />
            <Pagination
              onChange={(page) => setCurrentPage(page)}
              onShowSizeChange={(current, size) => setLimit(size)}
              total={totalItems}
              limit={limit}
              currentPage={currentPage}
            />
          </>
        ) : (
          <></>
        )}
      </Card>
    </div>
  );
}
