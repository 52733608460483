import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function Addresses() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode irá ver um relatório de todas as suas doações já realizadas."
    />
  );
}
