export default {
  back: {
    original: 'Back',
    text: 'Voltar',
  },
  login: {
    original: 'Login',
    text: 'Entrar',
  },
  next: {
    original: 'Next',
    text: 'Próxima',
  },
  privacy: {
    original: 'Privacy',
    text: 'Privacidade',
  },
  terms: {
    original: 'Terms',
    text: 'Termos',
  },
  logout: {
    original: 'Logout',
    text: 'Sair',
  },
  settings: {
    original: 'Settings',
    text: 'Configurações',
  },
  language: {
    original: 'Language',
    text: 'Idioma',
  },
};
