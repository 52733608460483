export default {
  loading: {
    original: 'Loading',
    text: 'Carregando',
  },
  almostThere: {
    original: 'Almost there',
    text: 'Quase lá',
  },
  itWasntSupposedToTakeThatLong: {
    original: "It wasn't supposed to take that long",
    text: 'Não era para demorar tanto assim',
  },
  sorryForTheDelay: {
    original: 'Sorry for the delay',
    text: 'Desculpa pela demora',
  },
  tryAgainLater: {
    original: 'Try again later',
    text: 'Tente novamente mais tarde',
  },
};
