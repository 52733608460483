import React, { useContext } from 'react';
//Contexts
import { AuthContext } from '~/contexts/AuthContext';
//Pages
import GiverDescription from './GiverDescription';
import GiverDashboard from './GiverDashboard';

export default function AuthenticationCheck() {
  const { user } = useContext(AuthContext);
  return user ? <GiverDashboard /> : <GiverDescription />;
}
