import React, { memo } from "react";

import { Div, Tag } from "./style";

function TagsCell({ tags, tag, color, fieldName, random }) {
  const randomTags = ["tag", "tag"];
  const colors = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];
  if (random) {
    return (
      <Div>
        {randomTags.map((item, index) => {
          const tagColor =
            color || colors[Math.floor(Math.random() * colors.length)];
          return (
            <Tag color={tagColor} key={index} style={{ marginBottom: 5 }}>
              {item.toUpperCase()}-{tagColor.toUpperCase()}
            </Tag>
          );
        })}
      </Div>
    );
  }
  if (tags) {
    return (
      <Div>
        {tags.map((item) => {
          const tagColor =
            color || colors[Math.floor(Math.random() * colors.length)];
          return (
            <Tag color={tagColor} key={item._id}>
              {item[fieldName].toUpperCase()}
            </Tag>
          );
        })}
      </Div>
    );
  }
  if (tag) {
    return (
      <Div>
        <Tag color={color || colors[Math.floor(Math.random() * colors.length)]}>
          {tag}
        </Tag>
      </Div>
    );
  }
}

export default memo(TagsCell);
