import React, { memo, useContext } from 'react';

import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Text from '~/components/typography/text';

import { Flex } from './style';

import { CapitalizeString } from '~/utils/Transformations';

function PrimaryAndSecondaryTextCell({ primaryText, secondaryText }) {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Flex direction="column" align={isMobile ? 'center' : 'flex-start'}>
      <Text style={{ textAlign: isMobile ? 'center' : 'left' }} strong>
        {CapitalizeString(primaryText)}
      </Text>
      <Text style={{ textAlign: isMobile ? 'center' : 'left' }}>
        {CapitalizeString(secondaryText)}
      </Text>
    </Flex>
  );
}

export default memo(PrimaryAndSecondaryTextCell);
