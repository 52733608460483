import styled from "styled-components";

export const Div = styled.div``;

export const Tag = styled.div`
  display: flex;
  width: ${(props) => `${props.width}px`};
  justify-content: space-around;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  margin: ${(props) => props.margin || "0"};
  border-radius: 8px;
  padding: 4px 5px;

  & svg {
    color: ${(props) => props.color};
  }
`;
