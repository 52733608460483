import React, { useContext } from 'react';
import { AiOutlineTransaction} from 'react-icons/ai';
// Contexts
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { ThemeContext } from '~/contexts/ThemeContext';
// import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Sidebar from '~/components/sidebar';
import MainNavbar from '~/components/navbar/Main';
// Pages
import Moves from './pages/Moves';

const { Content } = Layout;

export default function Profile() {
  const { theme } = useContext(ThemeContext);
  // const { translate } = useContext(LanguageContext);

  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: 'Movimentações',
      url: '/movimentacoes',
      icon: AiOutlineTransaction,
    },
    {
      index: 2,
      title: 'Last',
    },
  ];

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar />
          <Content
            style={{
              overflow: 'auto',
            }}
          >
            <Switch>
              <Route exact path={`/`}>
                <Redirect to="/movimentacoes" />
              </Route>
              <Route path={`/movimentacoes`} component={Moves} />
              <Route exact path={`*`}>
                <Redirect to="/movimentacoes" />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
