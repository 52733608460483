import React, { useContext, memo } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Libraries
import { Tooltip } from 'antd';

function AntTooltip({ placement, title, children }) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      placement={placement || 'top'}
      title={title}
      overlayStyle={{
        backgroundColor: theme.color.tooltipBackground,
      }}
    >
      {children}
    </Tooltip>
  );
}

export default memo(AntTooltip);
