import React from 'react';

import Table from '~/components/table';

import {
  ImageWithTextCell,
  PrimaryAndSecondaryTextCell,
  DateAndTimeCell,
} from '~/components/table/cells';

import { MEFormatConversion, CapitalizeString } from '~/utils/Transformations';

export default function MovesTable({ moves }) {
  const columns = [
    {
      title: 'Data da transação',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '40%',
      render: (created_at) => <DateAndTimeCell timestamp={created_at} />,
    },
    {
      title: 'Recebidor',
      dataIndex: 'receiver_person_name',
      key: 'receiver_person_name',
      width: '30%',
      render: (receiver_person_name, record) => (
        <ImageWithTextCell
          text={
            receiver_person_name
              ? CapitalizeString(receiver_person_name)
              : 'Nome de quem recebeu'
          }
          src={record.receiver_person_avatar || ''}
          size={40}
        />
      ),
    },

    {
      title: 'Item doado',
      dataIndex: '_id',
      key: '_id',
      render: (_id, record) => (
        <PrimaryAndSecondaryTextCell
          primaryText={record.donation_product_service_name}
          secondaryText={MEFormatConversion(record.amount)}
        />
      ),
    },
  ];

  return <Table tableColumns={columns} dataReceived={moves} />;
}
