import { format, addHours, differenceInDays } from 'date-fns';

export const HourInBrazilianFormat = (date) => {
    return format(new Date(date), 'HH:mm');
};
export const DateInBrazilianFormat = (date) => {
    const newDate = addHours(new Date(date), 3);
    return format(new Date(newDate), 'dd/MM/yyyy');
};
export const DiferenceBetweenTheCurrentDate = (date) => {
    const currentDate = new Date();

    return differenceInDays(currentDate, new Date(date));
};